import React from 'react'

const Look = () => {
    return (
        <div className='containermd:py-16 mt-4 px-6 md:px-14 -mb-[100px] relative z-[1]'>
            <div className=' mx-auto bg-[#00c46f]  py-4 sm:py-6 md:py-8 lg:py-10 px-4 rounded-md'>
                <div className='flex items-center gap-x-5 gap-y-2 flex-wrap md:flex-nowrap text-white px-4 md:px-8 justify-center md:justify-normal'>
                    <h3 className='text-[30px] sm:text-[34px] md:text-[36px] font-bold'>Still Looking for an Drop Taxi ?</h3>
                    <a href="tel:+919952267560" className='th-btn-reverse look-btn'>CALL +919952267560 </a>
                </div>
            </div>
        </div>
    )
}

export default Look