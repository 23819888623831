import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import PricePlan from "../components/Home/PricePlan";

const Home = () => {
  return (
    <>
      <BookingForm />
      <About />
      <OurServices />
      <OneWayServices />
      <TopRoutes />
      <PricePlan />
      {/* <PopularRoutes />
      <Cars /> */}
      <Look />
    </>
  );
};

export default Home;
