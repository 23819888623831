import React from 'react'
import { Link } from 'react-router-dom'

const OneWayServicesComponent = ({place , image, desc}) => {
  return (
    <div className="bg-[#2A2F3C] flex flex-col rounded-lg p-4">
    <img src={image} className=' h-[210px] object-cover' />
    <div className=' flex flex-col flex-1 mt-2'>
      <div className=' flex-1 flex flex-col gap-y-1'>
      <Link to="" className=" text-[28px] md:text-[30px] lg:text-[32px] text-center block font-semibold hover:text-[#00c46f] transition-all">{place}</Link>
      <p>{desc}</p>
      </div>
      <div className=" text-center mt-2">
      <a  href="tel:+919952267560" className='th-btn mt-3'>BOOK NOW</a>
      </div>
    </div>
  </div>
  )
}

export default OneWayServicesComponent
