import React from "react";
import banner from "../assets/form_banner.jpg";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";

const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  return (
    <div className="form_bg relative bg-fixed" style={divStyle}>
      <div className="container py-28 mx-auto px-0 sm:px-4 md:px-8 lg:px-16 relative z-10">
        <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 items-center">
          <div className="bg-white  rounded-lg px-4  pb-4 " id="book_taxi_form">
            <Form />
          </div>
          <div className="w-full md:w-[90%] mx-auto md:ml-auto px-4 sm:px-0">
            <p className=" text-white font-semibold text-lg">
              Book Drop Taxi Service with Sky Groups
            </p>
            <div className=" text-6xl text-white font-bold mt-4">
              Welcome to
            </div>
            <div className=" text-6xl text-white font-bold mt-4">
              Sky Groups Taxi
            </div>
            <div className="mt-4 text-lg text-white">
              {" "}
              Your Reliable and Affordable drop taxi. Sit back and enjoy
              hassle-free travel with our professional drivers and comfortable
              vehicles. Book now for seamless transportation and a smooth
              journey.
            </div>
            <div className=" text-white font-bold text-lg mt-10">
              <a
                href="tel:+919952267560"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> +91 9952267560
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
