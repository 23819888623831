import React from 'react'
import { Link } from 'react-router-dom';
import bg from "../../assets/images/servbg.png";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

const Footer = () => {
    return (
        <div className="serv_parent  pt-5 sm:pt-10 md:pt-20 lg:pt-28 mt-10" style={{backgroundImage: `url(${bg})`}}>
             <div className=' container mx-auto px-4 md:px-8'>
                <div className=' flex gap-x-2 md:gap-x-5 justify-between flex-wrap lg:flex-nowrap gap-y-4 mt-16'>
                        <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]'>
                                <p>Sky Groups Taxi is a registered private limited company providing reliable and convenient one-way taxi services throughout Tamil Nadu, Pondicherry, Bangalore, Hyderabad, and Kochi. Experience the convenience and reliability of our services. Contact us today!</p>
                        </div>
                        <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]' id='contact'>
                                <h3 className=' font-semibold text-xl md:text-2xl text-white'>Contact Us</h3>
                                <ul className=' mt-5 flex flex-col gap-y-3 md:gap-y-6'>
                                    <li className=' flex gap-x-2 items-center'><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaLocationDot /></span><span>Coimbatore, Tamilnadu</span></li>
                                    <li><a href="tel:+919952267560"  className=' flex gap-x-2 items-center w-full group  '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaPhone   /></span><span className='group-hover:text-[#00c46f]'>9952267560</span></a></li>
                                    <li className=' flex gap-x-2 items-center'><a href='skygroupstaxi@gmail.com'  className=' flex gap-x-2 items-center w-full group '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><IoMail /></span><span className='group-hover:text-[#00c46f]'>skygroupstaxi@gmail.com</span></a></li>
                                </ul>
                        </div>
                        <div className=' w-full md:w-full lg:w-[50%] text-[#BDBDBD]'>
                        <h3 className=' font-semibold text-xl md:text-2xl text-white'>One Way Taxi Cities</h3>
                        <ul className=' mt-5 grid grid-cols-1 gap-x-2 gap-y-3  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
                            <ul className=' flex flex-col gap-y-3'>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Chennai</Link>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Coimbatore</Link>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Bangalore</Link>
                            </ul>
                            <ul className=' flex flex-col gap-y-3'>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Salem</Link>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Madurai</Link>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Pondicherry</Link>
                            </ul>
                            <ul className=' flex flex-col gap-y-3'>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Vellore</Link>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Trichy</Link>
                                <Link className='primary-color hover:text-[#fff] transition-all'>Kumbakonam</Link>
                            </ul>
                        </ul>

                        </div>
                </div>
            </div>
            <div className=' bg-black mt-3 sm:mt-5 md:mt-8 px-4 py-4 sm:py-7'>
                <ul className=' flex flex-col sm:flex-row justify-center gap-x-4 items-center'>
                    <li><Link to="/terms-and-conditions" className=' block primary-color hover:text-white transition-all'>Terms & Conditions</Link></li>
                    <li><Link to="/privacy-policy" className=' block primary-color hover:text-white transition-all'>Privacy Policy</Link></li>
                </ul>
            </div>
            <div className='py-4 sm:py-7 text-white bg-[#2a2f3c] text-center px-4'>
            Copyright @ 2024 <Link className='primary-color hover:text-[#fff] transition-all'>Sky Groups Taxi Private Limited</Link>. All Rights Reserved. Developed By <a href='https://netbixzie.in/' target='_blank' className='primary-color hover:text-[#fff] transition-all'> Netbix Technologies</a>
            </div>
        </div>
    )
}

export default Footer